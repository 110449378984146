import '../styles/pages/appPage.scss'
import React from 'react'
import { NpmDownloadAppPage } from '@plurix/ecom-pages'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const OurAppPreview = () => {
  return (
    <section className="npm-download__app">
      <GatsbySeo
        title="Baixe nosso aplicativo"
        description="Baixe nosso aplicativo da loja e aproveite nossas ofertas!"
      />
      <NpmDownloadAppPage segment="baixe-nosso-app" />
    </section>
  )
}

export default OurAppPreview
